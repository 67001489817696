<template>
  <div class="mt-1 p-2">
    <b-card>
      <div class="mb-1"><h2>Test Emails</h2></div>
      <b-overlay :show="loading" rounded="sm" no-fade>
        <div>
          <b-button class="primary" @click="sendTest">
          Send Test Emails
        </b-button>
        </div>

        
        <div class="container" v-show="permissionListData.length > 0">
        <table class="table mt-3 text-center">
          <thead>
            <tr>
              <th>Department</th>
              <th>Staff</th>
              <th>Date</th>
              <th>Current</th>
              <th>Requested</th>

            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in permissionListData" :key="index">
              <td>{{ item.department.title }}</td>
              <td>
                {{ item.staff.name }} <small>{{ item.staff.surname }}</small>
              </td>
              <td>
                {{ item.date }} <span v-if="item.historical_change == true"> <br> <b-badge variant="warning">Historical Change</b-badge></span>
                <span v-else-if="item.historical_change == 'Location Change'"> <br> <b-badge variant="info">Location Change</b-badge></span>
              </td>
              <!-- Eski yeniyi ters gösteriyoruz. Onay işleminden sonra düzeltilecek -->
              <td>
                <span class="p-50">{{ item.new_task.title }}</span>
              </td>
              <td>
                <span class="p-50">{{ item.old_task.title }}</span>
              </td>
              
            </tr>
          </tbody>
        </table>


      </div>
      <div v-show="permissionListData.length == 0">
        <hr />
        <p class="text-center mt-5 text-info" style="font-size: 2rem;">
          There are no pending change requests for this year.
        </p>
      </div>

       
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
} from "bootstrap-vue";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { onUnmounted } from "@vue/composition-api";
import settingsStoreModule from "../settingsStoreModule";
import vSelect from "vue-select";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    ToastificationContent,
  },

  setup() {
    const TASK_APP_STORE_MODULE_NAME = "settings";
    // Register module
    if (!store.hasModule(TASK_APP_STORE_MODULE_NAME))
      store.registerModule(TASK_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TASK_APP_STORE_MODULE_NAME))
        store.unregisterModule(TASK_APP_STORE_MODULE_NAME);
    });
  },

  computed: {
    dataMeta() {
      return {
        from:
          this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to:
          this.perPage * (this.currentPage - 1) + Number(this.perPage) <
          this.totalRows
            ? this.perPage * (this.currentPage - 1) + Number(this.perPage)
            : this.totalRows,
        of: this.totalRows,
      };
    },
  },

  data() {
    return {
      permissionListData: [],
      loading: true,
      perPage: localStorage.getItem("actionShow") ?? 25,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [25, 50, 100],
      fields: [
        {
          key: "title",
          label: "Action",
          class: "text-left",
        },
        {
          key: "user_name_surname",
          label: "User",
          sortable: true,
          class: "text-center",
        },

        {
          key: "ip_address",
          label: "IP Adress",
          sortable: false,
          class: "text-center",
        },
        {
          key: "created_at",
          label: "Date - Time",
          sortable: true,
          class: "text-center",
        },
      ],
    };
  },

  created() {

    this.getPermissionListReqChange();
  },

  methods: {



    getPermissionListReqChange() {
      this.loading = true;
      store
        .dispatch("settings/getAllPermissionListReqChange", {
          year: 2025,
        })
        .then((res) => {
          this.permissionListData = res.data;
          this.loading = false;

        })
        .catch((error) => {
          this.loading = false;

          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "An error occurred",
              text: "Please try again later or contact support.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    sendTest(){
      console.log("button clicked")
      this.loading = true;

      

      store
        .dispatch("settings/sendTestEmails")
        .then((res) => {
          if(res.data=="ok"){
            this.loading = false;

            this.$toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              text: "Emails have been sent",
              icon: "AlertTriangleIcon",
              variant: "success",
            },
          });
          }
        })
        .catch((error) => {
          this.$router.push({ name: "settings" });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "An error occurred",
              text: "Please try again later or contact support.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
